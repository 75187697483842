import BaseDataRepository from '../BaseData';

const primaryKeyName = 'ugid';
const objectPluralName = 'UserGroups';

class UserGroupsRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName);
  }
}

export default UserGroupsRepository;
