import BaseDataRepository from '../BaseData';
import UserNotificationsRepository from '../UserNotifications';

const primaryKeyName = 'nid';
const objectPluralName = 'NotificationList';
const objectSingularName = 'Notification';
const objectPluralNameAsCamelCase = 'notificationList';

class NotificationListRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName, objectSingularName, objectPluralNameAsCamelCase);
    this.userNotifications = new UserNotificationsRepository(firebaseApp);
  }
  getUserNotifications = async (nid, includeRead = true, includeInactive = false) => {
    const userNotifications = (await this.userNotifications.getDbItemsAsArray(includeInactive))
      .filter(un =>
        un.nid === nid &&
        (includeRead || !un.isRead));
    return userNotifications || [];
  };
}

export default NotificationListRepository;
