import React, {
  useRef,
  useEffect,
  Suspense,
  useState
} from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import withReactContent from 'sweetalert2-react-content';
import routes from '../Routes';
import {
  // systemAdminRole,
  adminRole,
  teacherRole,
  studentRole,
  whanauRole,
  // undefinedRole
} from '../Domain/Roles';
import FormGroupRow, {
  inputTypes
} from '../FormGroupRow';
import {
  Validator
} from 'jsonschema';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_THEME
} from '../Domain';
import {
  groupType,
  classType
} from '../Domain/GroupTypes';
import AdminImportTable from '../AdminImportTable';
import profileImage from '../../assets/img/profile.png';

const DATE_MOMENT_FORMAT = 'DD/MM/YYYY';
const TIME_MOMENT_FORMAT = 'HH:mm:ss';
const DATE_TIME_MOMENT_FORMAT = `${DATE_MOMENT_FORMAT} ${TIME_MOMENT_FORMAT}`;
const ISO8601_DATE_FORMAT = 'YYYY-MM-DD';
const ISO8601_TIME_FORMAT = 'HH:mm:ss.SSSS';
const ISO8601_DATE_TIME_FORMAT = `${ISO8601_DATE_FORMAT}T${ISO8601_TIME_FORMAT}`;
const keyCodes = {
  backspace: 8,
  tab: 9,
  enter: 13,
  space: 32,
  arrowLeft: 37,
  arrowRight: 39,
  colon: 59,
  divide: 111,
  semiColon: 186,
  forwardSlash: 191
};
const isShiftPlusTab = (shiftKey, keyCode) => {
  if (!shiftKey) {
    return false;
  }
  return (keyCode === keyCodes.tab);
};
const isShiftSemiColon = (shiftKey, keyCode) => {
  if (!shiftKey) {
    return false;
  }
  return (keyCode === keyCodes.semiColon || keyCode === keyCodes.colon);
};
const isNumeric = keyCode => {
  return (keyCode >= 48 && keyCode <= 57)
    || (keyCode >= 96 && keyCode <= 105);
};
const isAlpha = keyCode => {
  return (keyCode >= 65 && keyCode <= 90);
};
const handleKeyDown = (handleKeyDownOptions) => {
  const {
    e,
    allowOptions,
    validKeyCodes
  } = handleKeyDownOptions,
    {
      shiftKey,
      which: keyCode
    } = e,
    {
      shiftPlusTab,
      shiftSemiColon,
      numeric,
      alpha
    } = allowOptions;
  if ((shiftPlusTab && isShiftPlusTab(shiftKey, keyCode))
    || (shiftSemiColon && isShiftSemiColon(shiftKey, keyCode))
    || (numeric && isNumeric(keyCode))
    || (alpha && isAlpha(keyCode))
    || validKeyCodes.includes(keyCode)) {
    return true;
  }
  e.preventDefault();
  return false;
};
const handleKeyDownForDate = e => {
  return handleKeyDown({
    e,
    allowOptions: {
      shiftPlusTab: true,
      shiftSemiColon: true,
      numeric: true
    },
    validKeyCodes: [
      keyCodes.backspace,
      keyCodes.enter,
      keyCodes.tab,
      keyCodes.arrowLeft,
      keyCodes.arrowRight,
      keyCodes.divide,
      keyCodes.forwardSlash
    ]
  });
};
const handleKeyDownForTime = e => {
  return handleKeyDown({
    e,
    allowOptions: {
      shiftPlusTab: true,
      shiftSemiColon: true,
      numeric: true
    },
    validKeyCodes: [
      keyCodes.backspace,
      keyCodes.enter,
      keyCodes.tab,
      keyCodes.arrowLeft,
      keyCodes.arrowRight
    ]
  });
};
const isEmptyString = value => value === '';
const isNullOrEmpty = value => value == null || isEmptyString(value);
const isTrueOrFalse = value => {
  const valueAsLowercaseString = (value || '').toString().toLowerCase();
  return valueAsLowercaseString === 'true' || valueAsLowercaseString === 'false';
};
const isBoolean = (value, expectedValue = undefined) =>
  !isNullOrEmpty(value) &&
  (typeof value === 'boolean' || isTrueOrFalse(value)) &&
  (isNullOrEmpty(expectedValue) || value.toString().toLowerCase() === expectedValue.toString().toLowerCase());
const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const getNewState = (s, nps, i, v) => {
  const np = nps[i];
  const stateToUse = i === 0
    ? s
    : {};
  const isLastNamePart = i === (nps.length - 1);
  const property = s[np]
    ? s[np]
    : {};
  const newValue = isLastNamePart
    ? v !== property ? v : property
    : {
      ...property,
      ...getNewState(property, nps, (i + 1), v)
    };
  const newState = {
    ...stateToUse,
    [np]: newValue
  }; // debugger;
  return newState;
};
const handleFieldChange = (e, setState, isCheckedOrCallbackFunction = undefined) => {
  const {
    name,
    value,
    checked,
    options
  } = e.target;
  const isCheckedOrCallbackFunctionType = typeof isCheckedOrCallbackFunction;
  const isChecked = isCheckedOrCallbackFunctionType === 'boolean' && isCheckedOrCallbackFunction === true;
  const isCallbackFunction = isCheckedOrCallbackFunctionType === 'function';
  const valueToUse = options
    ? (() => {
      const filteredOptions = Object.keys(options)
        .map(optionKey =>
          options[optionKey].selected && options[optionKey].value)
        .filter(v =>
          v !== false);
      if (filteredOptions.length === 1) {
        return filteredOptions[0];
      } else {
        return filteredOptions;
      }
    })()
    : isChecked
      ? checked
      : value;
  const nameParts = name.split('.');
  setState(s => getNewState(s, nameParts, 0, valueToUse));
  if (isCallbackFunction) {
    isCheckedOrCallbackFunction(e);
  }
};
const defaultCustomSwalOptions = {
  buttonsStyling: false,
  backdrop: 'rgba(0, 0, 0, 0.85)',
  customClass: {
    popup: 'bg-pumahara',
    title: 'text-pumahara',
    content: 'z-index-2',
    input: 'form-control',
    confirmButton: 'btn btn-success text-uppercase mx-3 col-4',
    cancelButton: 'btn btn-danger text-uppercase mx-3 col-4'
  },
  target: 'div[data-theme]'
};
const customSwalMixin = props => {
  const reactSwal = withReactContent(Swal);
  return reactSwal.mixin(props);
};
const customSwal = async props => {
  return await customSwalMixin(defaultCustomSwalOptions).fire({
    ...defaultCustomSwalOptions,
    ...props
  });
};
const isEmailValid = email => !isNullOrEmpty(email) && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
const isPasswordValid = password => !isNullOrEmpty(password) && password.match(/(?:(?:(?=.*?[0-9])(?=.*?[-!@#$%&*ˆ+=_])|(?:(?=.*?[0-9])|(?=.*?[A-Z])|(?=.*?[-!@#$%&*ˆ+=_])))|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-!@#$%&*ˆ+=_]))[A-Za-z0-9-!@#$%&*ˆ+=_]{6,15}/);
const isDisplayNameValid = displayName => !isNullOrEmpty(displayName) && displayName.length > 1 && !displayName.match("[0-9]+");
const isUrlValid = url => !isNullOrEmpty(url) && url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
const fromCamelcaseToTitlecase = camelCase => camelCase.replace(/([A-Z])/g, match => ` ${match}`).replace(/^./, match => match.toUpperCase());
const toCamelcase = value => {
  return value.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) {
      return '';
    }
    return index === 0
      ? match.toLowerCase()
      : match.toUpperCase();
  });
};
const hasRole = (roles, role) => !!(roles || {})[role];
const hasRoles = (roles, rolesToCheck) => {
  let hasRolesConfirmed = false;
  Object.keys(roles).map(roleKey => {
    if (!hasRolesConfirmed) {
      hasRolesConfirmed = hasRole(rolesToCheck, roleKey);
    }
    return null;
  });
  return hasRolesConfirmed;
};
const isJson = jsonAsString => typeof toJson(jsonAsString) === 'object';
const toJson = (jsonAsString, defaultReturnValue = undefined) => {
  try {
    return JSON.parse(jsonAsString);
  } catch {
    return defaultReturnValue;
  }
};
const stripHtml = html => html.replace(/(<([^>]+)>)/ig, '');
const getFirstCharacters = (value, length, excludeEllipse = false) => {
  return value && value.length > length
    ? `${value.substring(0, length - (excludeEllipse ? 0 : 3))}${!excludeEllipse && '...'}`
    : value;
};
const draftToText = (draftRaw, defaultValue = undefined, maxLength = undefined) => {
  if (!isJson(draftRaw)) {
    return defaultValue !== undefined
      ? defaultValue
      : draftRaw;
  }
  const draftAsJson = JSON.parse(draftRaw);
  const draftAsHtml = draftToHtml(draftAsJson);
  const draftAsText = stripHtml(draftAsHtml);
  return isNumber(maxLength)
    ? getFirstCharacters(draftAsText, maxLength, false)
    : draftAsText;
};
const isNumber = value => value && !isNaN(value);
const toMoment = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => moment(value || new Date(), dateFormat);
const isDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => value && toMoment(value, dateFormat).isValid();
const toDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => toMoment(value, dateFormat).toDate();
const toFormattedDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).format(DATE_MOMENT_FORMAT)
  : value;
const toFormattedDateTime = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).format(DATE_TIME_MOMENT_FORMAT)
  : value;
const toTimeAgo = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).fromNow()
  : value;
const tryToConvertValue = value => {
  let convertedValue = undefined;
  let convertedValueType = undefined;
  if (isNumber(value)) {
    convertedValue = Number(value);
  } else if (isBoolean(value)) {
    convertedValue = Boolean(value);
  } else if (isDate(value)) {
    convertedValue = toDate(value);
  } else {
    convertedValue = value;
  }
  convertedValueType = typeof convertedValue;
  return {
    convertedValue,
    convertedValueType
  };
};
const handleCaret = (order, column) => {
  let iconName;
  switch (order) {
    case 'asc':
      iconName = 'fa-sort-up';
      break;
    case 'desc':
      iconName = 'fa-sort-down';
      break;
    default:
      iconName = 'fa-sort';
  }
  // console.log(`handleCaret.column: ${JSON.stringify(column, null, 2)}`);
  return (
    <i className={`react-bootstrap-table-caret ${column.dataField} fas ${iconName}`} />
  );
};
const getValue = (object, key = undefined, keySeparator = '.') => {
  let value = object;
  if (typeof key === 'string') {
    const keyParts = key.split(keySeparator);
    if (keyParts.length === 1) {
      value = object[key]
    } else {
      const newKey = keyParts.slice(1).join(keySeparator);
      value = getValue(object, newKey);
    }
  }
  return value;
};
const handleSort = (a, b, sortOrder = 'asc', sortName = undefined, sortNameSeparator = '.') => {
  const {
    convertedValue: aValue,
    convertedValueType: aValueType
  } = tryToConvertValue(getValue(a, sortName, sortNameSeparator));
  const {
    convertedValue: bValue,
    convertedValueType: bValueType
  } = tryToConvertValue(getValue(b, sortName, sortNameSeparator));
  const result = sortOrder === 'asc'
    ? aValueType === 'number' && bValueType === 'number'
      ? bValue - aValue
      : aValue > bValue
        ? 1
        : aValue < bValue
          ? -1
          : 0
    : aValueType === 'number' && bValueType === 'number'
      ? aValue - bValue
      : bValue > aValue
        ? 1
        : bValue < aValue
          ? -1
          : 0;
  console.log(`${JSON.stringify({ sortName, sortOrder, aValue, aValueType, bValue, bValueType, result })}`);
  return result;
};
const sortArray = (array, sortName, sortOrder) => {
  console.log('array-before: ', JSON.stringify(array.map(item => `${sortName}: ${item[sortName]}`), null, 2));
  array.sort((a, b) => handleSort(a, b, sortOrder, sortName));
  console.log('array-after: ', JSON.stringify(array.map(item => `${sortName}: ${item[sortName]}`), null, 2));
};
const callFunction = async (backend, baseUrl, functionName, data) => {
  const functionsRepositoryOptions = {
    baseUrl,
    functionName,
    data
  };
  const result = await backend.functions.call(functionsRepositoryOptions);
  console.log(`${functionsRepositoryOptions.functionName}.result: ${JSON.stringify(result, null, 2)}`);
  return result;
};
const getDbItem = async (backend, dbObjectsName, id) => {
  const dbItem = await backend[dbObjectsName].getDbItemValue(id);
  if (dbItem) {
    return dbItem;
  }
  console.log(`${dbObjectsName} Id '${id}' not found.`);
  return null;
};
const getUser = async (backend, uid) => {
  return await getDbItem(backend, 'users', uid);
};
const getUserDisplayName = async (backend, uid) => {
  const dbUser = await getUser(backend, uid);
  if (dbUser) {
    return dbUser.displayName;
  }
  return 'UNKNOWN';
};
const generateResourceBundle = async (content, language) => {
  const resourceBundle = {};
  if (content) {
    await Promise.all(Object.keys(content).map(async contentKey => {
      let jsonNode = content[contentKey];
      const {
        en,
        mi
      } = jsonNode;
      if (typeof en !== 'undefined' && typeof mi !== 'undefined') {
        jsonNode = language === 'en'
          ? en
          : mi;
      }
      if (typeof jsonNode === 'string') {
        resourceBundle[contentKey] = jsonNode;
      } else {
        resourceBundle[contentKey] = await generateResourceBundle(jsonNode, language);
      }
    }));
  }
  return resourceBundle;
};
const constructResourceBundles = async (content, supportedLanguages) => {
  const resourceBundles = {};
  await Promise.all(Object.keys(supportedLanguages || {}).map(async supportedLanguageKey => { // debugger;
    const resourceBundle = await generateResourceBundle(content, supportedLanguageKey);
    resourceBundles[supportedLanguageKey] = resourceBundle;
    // i18n.addResourceBundle(supportedLanguageKey, 'common', resourceBundle);
    // console.log(`LanguageProvider.handleDbItemValueChange.i18n.addResourceBundle: ${supportedLanguageKey},\nresourceBundle: ${JSON.stringify(resourceBundle, null, 2)}`);
    // console.log(`LanguageProvider.handleDbItemValueChange.i18n.addResourceBundle: ${supportedLanguageKey}`);
    return null;
  }));
  return resourceBundles;
};
const deconstructResource = (content, supportedLanguageKey, newContent) => {
  for (const [key, value] of Object.entries(content)) {
    newContent[key] = newContent[key] || {};
    if (typeof value === 'object') {
      deconstructResource(value, supportedLanguageKey, newContent[key]);
    } else {
      newContent[key] = {
        ...newContent[key],
        [supportedLanguageKey]: value
      };
    }
  }
};
const deconstructResourceBundles = (content, supportedLanguages) => {
  let newContent = {};
  Object.keys(supportedLanguages || {}).map(supportedLanguageKey =>
    deconstructResource(content[supportedLanguageKey], supportedLanguageKey, newContent)
  );
  return newContent;
};
const groupBy = (array, key) => {
  const groupByReducer = (accumulator, currentValue, index, array, keyOverride) => {
    const keyParts = key.split('.');
    const currentValueKey = currentValue[keyOverride || keyParts[0]];
    if (typeof currentValueKey === 'object') {
      Object
        .keys(currentValueKey)
        .filter(k =>
          keyParts.length === 1 || k === keyParts[1]
        ).map(k => {
          if (keyParts.length === 1) {
            (accumulator[k] = accumulator[k] || []).push(currentValue);
          } else if (k === keyParts[1]) {
            (accumulator[k] = accumulator[k] || []).push(currentValue[keyParts[0]][keyParts[1]]);
          }
          return null;
        });
    } else {
      (accumulator[currentValueKey] = accumulator[currentValueKey] || []).push(currentValue);
    }
    return accumulator;
  };
  const groupedBy = array.reduce(groupByReducer, {});
  return groupedBy;
};
const downloadBlob = async (blob, fileName) => {
  const {
    body
  } = document;
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  body.appendChild(link);
  link.click();
  body.removeChild(link);
};
const {
  user,
  users,
  teacher,
  teachers,
  student,
  students,
  whanauMember,
  whanauMembers,
  group,
  groups,
  classesItem,
  classes
} = routes;
const getActiveRouteDetails = () => {
  const {
    pathname
  } = window.location;
  const pathnameParts = pathname.split('/');
  const pathnameId = pathnameParts.pop();
  let activeRoute, activeRoutes, activeRole, activeType;
  if (pathnameParts.length === 1) {
    switch (pathname) {
      case teachers.path:
        activeRoute = teachers;
        activeRole = teacherRole;
        break;
      case students.path:
        activeRoute = students;
        activeRole = studentRole;
        break;
      case whanauMembers.path:
        activeRoute = whanauMembers;
        activeRole = whanauRole;
        break;
      case groups.path:
        activeRoute = groups;
        activeType = groupType;
        break;
      case classes.path:
        activeRoute = classes;
        activeType = classType;
        break;
      case users.path:
      default:
        activeRoute = users;
        activeRole = adminRole;
        break;
    }
  } else {
    switch (pathname) {
      case teacher.path.replace(':uid', pathnameId):
        activeRoute = teacher;
        activeRoutes = teachers;
        activeRole = teacherRole;
        break;
      case student.path.replace(':uid', pathnameId):
        activeRoute = student;
        activeRoutes = students;
        activeRole = studentRole;
        break;
      case whanauMember.path.replace(':uid', pathnameId):
        activeRoute = whanauMember;
        activeRoutes = whanauMembers;
        activeRole = whanauRole;
        break;
      case group.path.replace(':gid', pathnameId):
        activeRoute = group;
        activeRoutes = groups;
        activeType = groupType;
        break;
      case classesItem.path.replace(':gid', pathnameId):
        activeRoute = classesItem;
        activeRoutes = classes;
        activeType = classType;
        break;
      case user.path.replace(':uid', pathnameId):
      default:
        activeRoute = user;
        activeRoutes = users;
        activeRole = adminRole;
        break;
    }
  }
  return {
    activeRoute,
    activeRoutes,
    activeRole,
    activeType
  };
};
const SuspenseFallback = () => <div className="pumahara-loading" />;
const importFile = async importFileOptions => {
  const handleUploadFile = async e => {
    e.preventDefault();
    defaultSteps[0].value = e.target.files[0];
    disableConfirmButton(false);
  };
  const validateData = async () => {
    const dataFile = defaultSteps[0].value;
    const fileReader = new FileReader();
    fileReader.onloadend = e => {
      const {
        result: dataAsText
      } = e.target;
      const dataAsJson = JSON.parse(dataAsText);
      const dataSchema = {
        type: 'array',
        items: {
          properties: {
            photoURL: {
              type: 'string'
            },
            emailAddress: {
              type: 'string'
            },
            displayName: {
              type: 'string'
            },
            role: {
              type: 'string'
            }
          },
          required: ['emailAddress', 'displayName', 'role']
        }
      };
      const v = new Validator();
      const validationResult = v.validate(dataAsJson, dataSchema);
      const validationErrors = validationResult
        ? validationResult.errors
        : [];
      const dataHasErrors = validationResult &&
        validationErrors &&
        Array.isArray(validationErrors) &&
        validationErrors.length > 0;
      console.log(`validateData.validationResult: ${JSON.stringify(validationResult, null, 2)}`); // debugger;
      defaultSteps[1].value = {
        validationErrors,
        dataAsJson
      };
      disableConfirmButton(dataHasErrors);
    };
    fileReader.readAsText(dataFile);
  };
  const handleAdminImportTableLoad = () => {
    const keyField = 'emailAddress';
    const columns = [
      {
        dataField: 'status',
        text: 'Status',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'photoURL',
        text: 'Photo URL',
        sort: false,
        style: {
          width: '85px',
          whiteSpace: 'break-spaces'
        },
        headerStyle: {
          width: '85px'
        },
        formatter: (cell, row) => <img className="lazyload" data-src={cell || profileImage} src={profileImage} alt={row.displayName} width="65" />
      },
      {
        dataField: keyField,
        text: 'Email Address',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'displayName',
        text: 'Display Name',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'role',
        text: 'Role',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      }
    ];
    const {
      validationErrors,
      dataAsJson
    } = defaultSteps[1].value;
    const hasValidationErrors = validationErrors &&
      Array.isArray(validationErrors) &&
      validationErrors.length > 0;
    const data = [];
    dataAsJson.map((d, index) => {
      const newData = {
        ...d,
        status: ''
      };
      if (hasValidationErrors) {
        const dataValidationErrors = validationErrors.filter(ve => ve.path && ve.path[0] === index);
        if (dataValidationErrors) {
          newData.status = dataValidationErrors.map(dve => dve.message).join(', ');
        }
      }
      data.push(newData);
      return null;
    });
    // debugger;
    return {
      keyField,
      columns,
      data: data
    };
  };
  const saveData = async () => {
    const getRoles = role => {
      const roles = {};
      const roleParts = role.split(',');
      roleParts.map(rolePart => {
        const roleName = toCamelcase(`${rolePart} Role`);
        roles[roleName] = roleName;
        return null;
      });
      return roles;
    };
    const saveAuthUsers = async (data, authUserUid) => {
      const {
        REACT_APP_GOOGLE_BASE_CLOUD_FUNCTIONS_URL: GCF_URL
      } = process.env;
      const authUsers = data.map(d => {
        const {
          photoURL,
          emailAddress,
          displayName,
          role
        } = d;
        const roles = getRoles(role);
        const newData = {
          disabled: false,
          displayName,
          email: emailAddress,
          photoURL: isUrlValid(photoURL)
            ? photoURL
            : undefined,
          isNew: true,
          createdBy: authUserUid,
          language: DEFAULT_LANGUAGE,
          providerData: [{
            email: emailAddress,
            providerId: 'password',
            uid: ''
          }],
          roles,
          theme: DEFAULT_THEME,
          updatedBy: authUserUid,
          version: 1
        };
        return newData;
      });
      const functionsRepositoryOptions = {
        baseUrl: GCF_URL,
        functionName: 'saveAuthUsers',
        data: {
          authUsers: authUsers
        }
      };
      const result = await backend.functions.call(functionsRepositoryOptions);
      // console.log(`${functionsRepositoryOptions.functionName}.result: ${JSON.stringify(result, null, 2)}`);
      return result;
    };
    const {
      dataAsJson: dataFile
    } = defaultSteps[1].value; // debugger;
    const savedAuthUserUids = await saveAuthUsers(dataFile, authUser.uid);
    defaultSteps[2].value = `Imported ${savedAuthUserUids.data.length} out of ${dataFile.length} users`;
  };
  const ImportText = props => {
    const [state, setState] = useState({
      isLoading: true,
      text: ''
    });
    const {
      isLoading,
      text
    } = state;
    useEffect(() => {
      if (isLoading) {
        const {
          onLoad
        } = props;
        setState(s => ({
          ...s,
          isLoading: false,
          text: onLoad()
        }));
      }
      return () => { }
    }, [props, isLoading]);
    return (
      <>
        <span>{text}</span>
      </>
    );
  };
  const {
    steps,
    t,
    backend,
    authUser,
    ...otherImportFileOptions
  } = importFileOptions;
  const UPLOAD_FILE_TITLE = 'Upload File';
  const VALIDATE_DATA_TITLE = 'Validate Data';
  const SAVE_DATA_TITLE = 'Save Data';
  const defaultSteps = [
    {
      title: UPLOAD_FILE_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.roundedPillInputWithIcon,
              primaryInputSm: 12,
              id: 'uploadFileInput',
              placeholder: t('app.common.uploadFile'),
              includeFileInput: true,
              onFileInputChange: handleUploadFile,
              readOnly: true
            }}
          />
        </Suspense>
      </>,
      value: null
    }, {
      title: VALIDATE_DATA_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.children,
              primaryInputSm: 12,
              primaryColClassName: 'admin-import-table-container table-responsive',
              primaryChildren: (
                <>
                  <AdminImportTable
                    onLoad={handleAdminImportTableLoad}
                  />
                </>
              )
            }}
          />
        </Suspense>
      </>,
      value: null
    }, {
      title: SAVE_DATA_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.children,
              primaryInputSm: 12,
              primaryChildren: (
                <>
                  <ImportText
                    onLoad={() => defaultSteps[2].value}
                  />
                </>
              )
            }}
          />
        </Suspense>
      </>,
      value: null
    }
  ];
  const stepsToUse = steps || defaultSteps;
  const customSwalOptions = {
    ...defaultCustomSwalOptions,
    progressSteps: stepsToUse.map((stepToUse, stepToUseIndex) => (stepToUseIndex + 1).toString()),
    reverseButtons: true,
    width: '100%'
  };
  const importFileSwal = customSwalMixin(customSwalOptions);
  const disableConfirmButton = disabled => {
    const confirmButton = importFileSwal.getConfirmButton();
    confirmButton.disabled = disabled;
  };
  for (let currentStep = 0; currentStep < stepsToUse.length;) { // debugger;
    if (currentStep === -1) {
      break;
    }
    const stepToUse = stepsToUse[currentStep];
    const {
      value,
      isConfirmed,
      isDismissed
    } = await importFileSwal.fire({
      title: stepToUse.title,
      html: stepToUse.html,
      confirmButtonText: currentStep < (stepsToUse.length - 1)
        ? t('app.common.next')
        : t('app.common.close'),
      cancelButtonText: currentStep === 0
        ? t('app.common.cancel')
        : t('app.common.previous'),
      showCancelButton: true,
      currentProgressStep: currentStep,
      didRender: () => {
        disableConfirmButton(isNullOrEmpty(stepToUse.value) && Number(stepToUse.value) === (stepsToUse.length - 1));
      },
      preConfirm: async () => {
        console.log(`stepToUse.title: ${stepToUse.title}`);
        switch (stepToUse.title) {
          case UPLOAD_FILE_TITLE:
          default:
            await validateData();
            break;
          case VALIDATE_DATA_TITLE:
            await saveData();
            break;
          case SAVE_DATA_TITLE:
            // eslint-disable-next-line
            window.location.href = window.location.href;
            break;
        }
      },
      ...otherImportFileOptions
    });
    stepToUse.value = value;
    if (isConfirmed) {
      currentStep++;
    } else if (isDismissed) {
      currentStep--;
    } else {
      break;
    }
  }
};
const {
  join
} = require('path');
const getFirebaseStorageURL = (projectId, source) => {
  const sourcePrefix = `firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/`;
  const sourceSuffix = `?alt=media`;
  const revisedSource = join(sourcePrefix, encodeURIComponent(source.startsWith('/')
    ? source.substring(1)
    : source));
  const firebaseStorageURL = `${'https://'}${revisedSource}${sourceSuffix}`;
  return firebaseStorageURL;
};
const Nz = (value, ifNullValue = undefined) => {
  return isNullOrEmpty(value)
    ? typeof ifNullValue === 'undefined'
      ? ''
      : ifNullValue
    : value;
};
const refactorObject = rest => {
  const refactoredObject = {};
  Object.keys(rest).map(k => {
    const refactoredValue = Nz(rest[k], null);
    if (refactoredValue !== null) {
      refactoredObject[k] = refactoredValue;
    }
    return null;
  });
  return refactoredObject;
};
const getParamsAsString = params => {
  return params
    ? `?${Object
      .keys(params)
      .map(key =>
        `${key}=${encodeURIComponent(params[key])}`)
      .join('&')}`
    : '';
};
const reduceArrayOfArrays = array => {
  const reducedArray = [].concat(...(array || []));
  return reducedArray;
};
const dedupe = array => {
  const dedupedArray = [...new Set(array || [])];
  return dedupedArray;
};

export {
  DATE_MOMENT_FORMAT,
  TIME_MOMENT_FORMAT,
  DATE_TIME_MOMENT_FORMAT,
  ISO8601_DATE_FORMAT,
  ISO8601_TIME_FORMAT,
  ISO8601_DATE_TIME_FORMAT,
  keyCodes,
  isShiftPlusTab,
  isShiftSemiColon,
  isNumeric,
  isAlpha,
  handleKeyDown,
  handleKeyDownForDate,
  handleKeyDownForTime,
  isEmptyString,
  isNullOrEmpty,
  isTrueOrFalse,
  isBoolean,
  usePrevious,
  getNewState,
  handleFieldChange,
  defaultCustomSwalOptions,
  customSwalMixin,
  customSwal,
  isEmailValid,
  isPasswordValid,
  isDisplayNameValid,
  isUrlValid,
  fromCamelcaseToTitlecase,
  toCamelcase,
  hasRole,
  hasRoles,
  isJson,
  toJson,
  stripHtml,
  getFirstCharacters,
  draftToText,
  isNumber,
  toMoment,
  isDate,
  toDate,
  toFormattedDate,
  toFormattedDateTime,
  toTimeAgo,
  tryToConvertValue,
  handleCaret,
  handleSort,
  getValue,
  sortArray,
  callFunction,
  getDbItem,
  getUser,
  getUserDisplayName,
  generateResourceBundle,
  constructResourceBundles,
  deconstructResourceBundles,
  groupBy,
  downloadBlob,
  getActiveRouteDetails,
  SuspenseFallback,
  importFile,
  getFirebaseStorageURL,
  Nz,
  refactorObject,
  getParamsAsString,
  reduceArrayOfArrays,
  dedupe
};
