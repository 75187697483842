import React, {
  useState,
  useEffect,
  lazy
} from 'react';
import PropTypes from 'prop-types';
import AppProvidersContext from '../Context';
import {
  I18nextProvider
} from 'react-i18next';

const AppProviders = props => {
  const {
    i18next,
    theme,
    language,
    backend,
    authUser,
    children
  } = props;
  const LoadingSpinner = lazy(async () => await import('../../../LoadingSpinner'));
  const BackendProvider = lazy(async () => await import('../../Backend/Provider'));
  const ThemeProvider = lazy(async () => await import('../../Theme/Provider'));
  const LanguageProvider = lazy(async () => await import('../../Language/Provider'));
  const AuthUserProvider = lazy(async () => await import('../../AuthUser/Provider'));
  const INITIAL_STATE = {
    isLoading: true,
    i18next: null,
    theme: '',
    language: '',
    authUser: null,
    children: null
  };
  const [state, setState] = useState(INITIAL_STATE);
  const {
    isLoading
  } = state;
  // console.log(`AppProvider.props: ${JSON.stringify({
  //   authUser: props.authUser
  // }, null, 2)}`);
  // console.log(`AppProvider.state: ${JSON.stringify({
  //   isLoading,
  //   authUser
  // }, null, 2)}`);
  useEffect(() => {
    setState(s => ({
      ...s,
      isLoading: false
    }));
    console.log(`AppProviders.useEffect called.`);
    return () => { };
  }, []);
  return (
    <>
      {console.log(`AppProviders.render called.`)}
      {
        isLoading
          ? <LoadingSpinner
            color="light"
            outerClassName="pumahara-loading"
          />
          : <>
            <AppProvidersContext.Provider
              value={{ i18next, backend, authUser }}
            >
              <BackendProvider backend={backend}>
                <I18nextProvider i18n={i18next}>
                  <ThemeProvider theme={theme}>
                    <LanguageProvider language={language}>
                      <AuthUserProvider authUser={authUser}>
                        {
                          children
                        }
                      </AuthUserProvider>
                    </LanguageProvider>
                  </ThemeProvider>
                </I18nextProvider>
              </BackendProvider>
            </AppProvidersContext.Provider>
          </>
      }
    </>
  );
};

AppProviders.propType = {
  backend: PropTypes.object.isRequired,
  authUser: PropTypes.object
};

export default AppProviders;
