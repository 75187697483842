import React, {
  lazy,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Route
} from 'react-router-dom';

const LoadingSpinner = lazy(async () => await import('../../LoadingSpinner'));
const INITAL_STATE = {
  isLoading: true,
  Layout: null,
  path: '',
  Component: null
};
const AppRoute = props => {
  const {
    layout: Layout,
    path,
    component: Component,
    ...rest
  } = props;
  const [state, setState] = useState(INITAL_STATE);
  const {
    isLoading
  } = state;
  // console.log(`AppRoute.props: ${JSON.stringify({
  //   Layout: props.layout,
  //   path: props.path,
  //   Component: props.component
  // }, null, 2)}`);
  // console.log(`AppRoute.state: ${JSON.stringify({
  //   isLoading,
  //   Layout,
  //   path,
  //   Component,
  //   ...rest
  // }, null, 2)}`);
  useEffect(() => {
    setState(s => ({
      ...s,
      isLoading: false
    }));
    console.log(`AppRoute.useEffect called.`);
    return () => { };
  }, []);
  return (
    <>
      {
        isLoading
          ? <LoadingSpinner
            color="light"
            outerClassName="pumahara-loading"
          />
          : <>
            <Route
              path={path}
              render={routeProps => (
                <Layout
                  {...routeProps}
                  {...rest}
                >
                  {console.log(`AppRoute.render called.`)}
                  <Component
                    {...routeProps}
                    {...rest}
                  />
                </Layout>
              )}
              {...rest}
            />
          </>
      }
    </>
  );
};

AppRoute.propTypes = {
  layout: PropTypes.object.isRequired,
  path: PropTypes.string,
  component: PropTypes.object.isRequired
};
AppRoute.defaultProps = {
  path: '/'
};

export default AppRoute;
