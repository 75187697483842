import 'firebase/auth';
import {
  whanauRole
} from '../../../../Domain/Roles';
import {
  customSwal,
  toFormattedDateTime,
  DATE_TIME_MOMENT_FORMAT,
  isNumber
} from '../../../../App';
import {
  UsersRepository,
  StorageRepository
} from '../../Repositories';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_THEME
} from '../../../../Domain';

class AuthenticationRepository {
  constructor(firebaseApp) {
    const {
      REACT_APP_USE_EMULATOR,
      REACT_APP_ATH_PORT
    } = process.env;
    this.auth = firebaseApp.auth(); // debugger;
    if (REACT_APP_USE_EMULATOR === 'true' && isNumber(REACT_APP_ATH_PORT)) {
      this.auth.useEmulator(`http://localhost:${REACT_APP_ATH_PORT}`);
      console.log(`AuthenticationRepository.auth.useEmulator is set to: 'localhost:${REACT_APP_ATH_PORT}'`);
    }
    this.users = new UsersRepository(firebaseApp);
    this.emailAuthProvider = firebaseApp.auth.EmailAuthProvider;
    this.storage = new StorageRepository(firebaseApp);
  }
  createUserWithEmailAndPassword = async (
    email,
    password,
    role,
    displayName,
    language,
    theme,
    createUserWithEmailAndPassword_Completed
  ) => {
    const authUser = await this.auth.createUserWithEmailAndPassword(email, password);
    const {
      uid,
      providerData
    } = authUser.user;
    const nowDateTime = toFormattedDateTime(new Date(), DATE_TIME_MOMENT_FORMAT);
    this.sendEmailVerification();
    this.users.saveDbItem({
      active: true,
      created: nowDateTime,
      createdBy: uid,
      displayName: displayName || '',
      email: email,
      emailVerified: false,
      language: language || DEFAULT_LANGUAGE,
      photoURL: '',
      providerData: providerData,
      roles: {
        [role]: role || whanauRole
      },
      theme: theme || DEFAULT_THEME,
      uid: uid,
      updated: nowDateTime,
      updatedBy: uid,
      version: 1,
      isNew: true
    }, createUserWithEmailAndPassword_Completed);
    return authUser;
  }
  authUserListener = async (next, fallback) => {
    const handleAuthStateChanged = async authUser => {
      if (authUser) {
        const {
          uid: authUserUid,
          emailVerified: authUserEmailVerified
        } = authUser;
        const dbUser = await this.users.getDbItemValue(authUserUid);
        const {
          uid: dbUserUid,
          emailVerified: dbUserEmailVerified,
          ...dbUserRest
        } = (dbUser || {});
        const combinedUser = {
          uid: authUserUid,
          emailVerified: authUserEmailVerified && dbUserEmailVerified,
          ...dbUserRest
        };
        // console.log(`combinedUser: ${JSON.stringify(combinedUser, null, 2)}`);
        next(combinedUser);
      } else {
        fallback();
      }
    };
    return await this.auth.onAuthStateChanged(handleAuthStateChanged);
  }
  updateProfile = async profile => {
    return await this.auth.currentUser.updateProfile(profile);
  }
  signInWithEmailAndPassword = async (email, password) => {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }
  signOut = async () => {
    console.log(`Current User: ${this.auth.currentUser}`);
    return await this.auth.signOut();
  }
  sendPasswordResetEmail = async email => {
    return await this.auth.sendPasswordResetEmail(email);
  }
  sendEmailVerification = async () => {
    return await this.auth.currentUser.sendEmailVerification();
  }
  reauthenticate = async currentPassword => {
    const user = this.auth.currentUser;
    const userCredentials = await this.emailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return await user.reauthenticateWithCredential(userCredentials);
  }
  getUserCredentials = async (providerId, t) => {
    let result = null;
    let errorMessage = null;
    try {
      if (providerId === 'password') {
        result = await customSwal({
          title: t('app.common.password'),
          input: 'password',
          inputPlaceholder: t('app.common.enterYourPassword'),
          confirmButtonText: t('app.common.ok'),
          cancelButtonText: t('app.common.cancel'),
          showCancelButton: true,
          footer: t('app.common.verifyCredentials')
        });
        if (result.isConfirmed) {
          return await this.reauthenticate(result.value);
        }
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      console.log('Get User Credentials Error: ', errorMessage);
      return errorMessage;
    }
  }
  changeEmail = async (e, t) => {
    e.preventDefault();
    const user = this.auth.currentUser;
    const {
      uid
    } = user;
    let result = null;
    let newEmail = null;
    let errorMessage = null;
    try {
      const user = this.auth.currentUser;
      const providerId = user.providerData[0].providerId;
      const userCredentials = await this.getUserCredentials(providerId, t);
      if (!userCredentials) {
        errorMessage = t('app.common.credentialsNotVerified')
      } else if (typeof userCredentials === 'string') {
        errorMessage = userCredentials;
      } else {
        result = await customSwal({
          title: t('app.common.emailAddress'),
          input: 'email',
          inputPlaceholder: t('app.common.enterYourEmailAddress'),
          confirmButtonText: t('app.common.ok'),
          cancelButtonText: t('app.common.cancel'),
          showCancelButton: true
        });
        newEmail = result.value;
        if (newEmail) {
          try {
            await user.updateEmail(newEmail);
            if (!user.emailVerified) {
              await user.sendEmailVerification();
              await this.users.saveDbItem({
                uid: uid,
                email: newEmail,
                updatedBy: uid,
                version: user.version
              });
              const {
                // eslint-disable-next-line
                value: confirmed
              } = await customSwal({
                icon: 'success',
                title: t('app.common.changeObjectSuccessful', { objectName: t('app.common.emailAddress') }),
                text: t('app.common.loginAgain'),
                confirmButtonText: t('app.common.ok')
              });
              await this.signOut();
              return;
            }
          } catch (error) {
            errorMessage = t('app.common.unableToUpdateObject', { objectName: t('app.common.emailAddress'), errorMessage: error.message });
          }
        }
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      customSwal({
        icon: 'error',
        title: t('app.common.changeObjectError', { objectName: t('app.common.emailAddress') }),
        html: errorMessage,
        confirmButtonText: t('app.common.ok')
      });
      console.log('Change Email Error: ', errorMessage);
    }
  }
  changePassword = async (e, t) => {
    e.preventDefault();
    let result = null;
    let newPassword = null;
    let confirmPassword = null;
    let errorMessage = null;
    try {
      const user = this.auth.currentUser;
      const providerId = user.providerData[0].providerId;
      const userCredentials = await this.getUserCredentials(providerId, t);
      if (!userCredentials) {
        errorMessage = t('app.common.credentialsNotVerified')
      } else if (typeof userCredentials === 'string') {
        errorMessage = userCredentials;
      } else {
        result = await customSwal({
          title: t('app.common.password'),
          input: 'password',
          inputPlaceholder: t('app.common.enterYourPassword'),
          confirmButtonText: t('app.common.ok'),
          cancelButtonText: t('app.common.cancel'),
          showCancelButton: true
        });
        newPassword = result.value;
        result = await customSwal({
          title: t('app.common.confirmPassword'),
          input: 'password',
          inputPlaceholder: t('app.views.signUp.reEnterYourPassword'),
          confirmButtonText: t('app.common.ok'),
          cancelButtonText: t('app.common.cancel'),
          showCancelButton: true
        });
        confirmPassword = result.value;
        if (newPassword && confirmPassword && newPassword === confirmPassword) {
          try {
            await this.auth.currentUser.updatePassword(newPassword);
            const {
              // eslint-disable-next-line
              value: confirmed
            } = await customSwal({
              icon: 'success',
              title: t('app.common.changeObjectSuccessful', { objectName: t('app.common.password') }),
              text: t('app.common.loginAgain'),
              confirmButtonText: t('app.common.ok')
            });
            await this.signOut();
            return;
          } catch (error) {
            errorMessage = t('app.common.unableToUpdateObject', { objectName: t('app.common.password'), errorMessage: error.message });
          }
        } else {
          errorMessage = `${t('app.common.password')} ${t('app.common.doesNotMatch')} ${t('app.common.confirmPassword')}.`;
        }
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      customSwal({
        icon: 'error',
        title: t('app.common.changeObjectError', { objectName: t('app.common.password') }),
        html: errorMessage,
        confirmButtonText: t('app.common.ok')
      });
      console.log('Change Password Error: ', errorMessage);
    }
  }
  deleteAccount = async (e, t) => {
    e.preventDefault();
    let result = null;
    let errorMessage = null;
    try {
      result = await customSwal({
        icon: 'warning',
        title: t('app.common.areYouSure'),
        text: t('app.common.youWontBeAbleToUndoThis'),
        confirmButtonText: t('app.common.ok'),
        cancelButtonText: t('app.common.cancel'),
        showCancelButton: true
      });
      if (result.isConfirmed) {
        const {
          currentUser
        } = this.auth;
        const {
          providerId
        } = currentUser.providerData[0];
        const userCredentials = await this.getUserCredentials(providerId, t);
        if (!userCredentials) {
          errorMessage = t('app.common.credentialsNotVerified')
        } else if (typeof userCredentials === 'string') {
          errorMessage = userCredentials;
        } else {
          const {
            user
          } = userCredentials;
          const {
            uid
          } = user;
          if (providerId === 'password') {
            const userPhotoFolderUrl = `/images/users/${uid}`;
            const storageFiles = await this.storage.getStorageFiles(userPhotoFolderUrl);
            await Promise.all(storageFiles.items.map(async storageFileItem => await storageFileItem.delete()));
          }
          await this.users.deleteDbItem(uid);
          await user.delete();
          await this.signOut();
          customSwal({
            icon: 'success',
            title: t('app.common.deleteObjectSuccessful', { objectName: t('app.common.account') }),
            text: t('app.common.objectHasBeenDeleted', { objectName: t('app.common.account') }),
            confirmButtonText: t('app.common.ok')
          });
        }
      }
    } catch (error) {
      errorMessage = error.message;
      console.log(`Delete Account Error: ${errorMessage}`);
    }
    if (errorMessage) {
      customSwal({
        icon: 'error',
        title: t('app.common.deleteObjectError', { objectName: t('app.common.account') }),
        html: errorMessage,
        confirmButtonText: t('app.common.ok')
      });
    }
  }
}

export default AuthenticationRepository;
