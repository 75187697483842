import BaseDataRepository from '../BaseData';
import UserNotificationsRepository from '../UserNotifications';
import NotificationListRepository from '../NotificationList';
import UserConnectionsRepository from '../UserConnections';
import UserGroupsRepository from '../UserGroups';
import GroupsRepository from '../Groups';
import {
  groupType
} from '../../../GroupTypes';
import UserJournalsRepository from '../UserJournals';
import JournalListRepository from '../JournalList';
import {
  isDate,
  toMoment
} from '../../../../App';

const primaryKeyName = 'uid';
const objectPluralName = 'Users';
const defaultHandleDbUserJournalsAsArrayChange = async (
  userJournalsAsArray,
  includeInactive = false,
  includeRead = true,
  includeJournals = false,
  filterStartDate = undefined,
  filterEndDate = undefined,
  filterCategory = undefined,
  includeUnapproved = false,
  journalListRepository = undefined,
  handleUserJournalsChange = undefined
) => { // debugger;
  let userJournals = userJournalsAsArray
    .filter(uj =>
      (includeRead || !uj.isRead)); // debugger;
  const filterStartDateAsMoment = isDate(filterStartDate)
    ? toMoment(filterStartDate).startOf('day')
    : filterStartDate;
  const filterEndDateAsMoment = isDate(filterEndDate)
    ? toMoment(filterEndDate).startOf('day')
    : filterEndDate;
  if (includeJournals) {
    await Promise.all(userJournals.map(async userJournal => {
      const journal = await journalListRepository.getDbItemValue(userJournal.jid);
      if (!includeInactive && journal.active) {
        userJournal.journal = journal;
      }
      return null;
    }));
  }
  userJournals = (userJournals || [])
    .filter(uj =>
      (!includeInactive || uj.active) &&
      (!includeJournals || includeUnapproved || (uj.journal && uj.journal.approved)) &&
      (!includeJournals || !filterStartDateAsMoment || !filterEndDateAsMoment || (uj.journal && toMoment(uj.journal.created).startOf('day').isBetween(filterStartDateAsMoment, filterEndDateAsMoment, undefined, '[]'))) &&
      (!includeJournals || !filterCategory || filterCategory === 'All' || (uj.journal && uj.journal.categories && uj.journal.categories.includes(filterCategory))));
  if (typeof handleUserJournalsChange === 'function') {
    await handleUserJournalsChange(userJournals);
  }
  return userJournals;
};

class UsersRepository extends BaseDataRepository {
  constructor(firebaseApp, groupsRepository = undefined, journalListRepository = undefined) {
    super(firebaseApp, primaryKeyName, objectPluralName);
    this.userNotifications = new UserNotificationsRepository(firebaseApp);
    this.notifications = new NotificationListRepository(firebaseApp);
    this.userConnections = new UserConnectionsRepository(firebaseApp);
    this.userGroups = new UserGroupsRepository(firebaseApp);
    this.groups = (groupsRepository || new GroupsRepository(firebaseApp, this));
    this.userJournals = new UserJournalsRepository(firebaseApp);
    this.journals = (journalListRepository || new JournalListRepository(firebaseApp, this));
  }
  getUserNotifications = async (uid, includeInactive = false, includeRead = true, includeNotifications = false, filterStartDate = undefined, filterEndDate = undefined, filterCategory = undefined) => {
    const userNotificationsAsArray = await this.userNotifications.getDbItemsAsArray(includeInactive, 'uid', uid);
    const userNotifications = userNotificationsAsArray
      .filter(un =>
        (includeRead || !un.isRead)); // debugger;
    const filterStartDateAsMoment = isDate(filterStartDate)
      ? toMoment(filterStartDate).startOf('day')
      : filterStartDate;
    const filterEndDateAsMoment = isDate(filterEndDate)
      ? toMoment(filterEndDate).startOf('day')
      : filterEndDate;
    if (includeNotifications) {
      await Promise.all(userNotifications.map(async userNotification => {
        const notification = await this.notifications.getDbItemValue(userNotification.nid);
        if (!includeInactive && notification.active) {
          userNotification.notification = notification;
        }
        return null;
      }));
    }
    return (userNotifications || [])
      .filter(un =>
        (!includeInactive || un.active) &&
        (!includeNotifications || !filterStartDateAsMoment || !filterEndDateAsMoment || (un.notification && toMoment(un.notification.created).startOf('day').isBetween(filterStartDateAsMoment, filterEndDateAsMoment, undefined, '[]'))) &&
        (!includeNotifications || !filterCategory || filterCategory === 'All' || (un.notification && un.notification.categories && un.notification.categories.includes(filterCategory))));
  };
  getUserConnections = async (uid, includeInactive = false, includeConnections = false) => {
    const userConnectionsAsArray = await this.userConnections.getDbItemsAsArray(includeInactive);
    const userConnections = userConnectionsAsArray
      .filter(uc =>
        uc.uid === uid ||
        uc.cid === uid
      );
    if (includeConnections) {
      await Promise.all(userConnections.map(async userConnection => {
        const connection = await this.getDbItemValue(userConnection.cid === uid
          ? userConnection.uid
          : userConnection.cid);
        if (!includeInactive && connection.active) {
          userConnection.connection = connection;
        }
        return null;
      }));
    }
    return (userConnections || [])
      .filter(uc =>
        (!includeInactive || uc.active) &&
        (!includeConnections || uc.connection));
  };
  getUserGroups = async (uid, includeInactive = false, includeGroups = false, type = groupType) => {
    const userGroups = await this.userGroups.getDbItemsAsArray(includeInactive, 'uid', uid);
    if (includeGroups) {
      await Promise.all(userGroups.map(async userGroup => { // debugger;
        const group = await this.groups.getDbItemValue(userGroup.gid);
        if (!includeInactive && group && group.active) {
          userGroup.group = group;
        }
        return null;
      }));
    }
    return (userGroups || [])
      .filter(ug =>
        (!includeInactive || ug.active) &&
        (!includeGroups || !type || (ug.group && ug.group.type === type)));
  };
  getUserJournals = async (
    uid,
    includeInactive = false,
    includeRead = true,
    includeJournals = false,
    filterStartDate = undefined,
    filterEndDate = undefined,
    filterCategory = undefined,
    includeUnapproved = false
  ) => {
    const userJournalsAsArray = await this.userJournals.getDbItemsAsArray(includeInactive, 'uid', uid);
    return await defaultHandleDbUserJournalsAsArrayChange(
      userJournalsAsArray,
      includeInactive,
      includeRead,
      includeJournals,
      filterStartDate,
      filterEndDate,
      filterCategory,
      includeUnapproved,
      this.journals
    );
  };
  onUserJournals = async (
    uid,
    includeInactive = false,
    includeRead = true,
    includeJournals = false,
    filterStartDate = undefined,
    filterEndDate = undefined,
    filterCategory = undefined,
    includeUnapproved = false,
    handleUserJournalsChange = undefined
  ) => {
    const handleDbUserJournalsAsArrayChange = async userJournalsAsArray => {
      return await defaultHandleDbUserJournalsAsArrayChange(
        userJournalsAsArray,
        includeInactive,
        includeRead,
        includeJournals,
        filterStartDate,
        filterEndDate,
        filterCategory,
        includeUnapproved,
        this.journals,
        handleUserJournalsChange
      );
    };
    return await this.userJournals.onDbItemsAsArrayChange(includeInactive, 'uid', uid, handleDbUserJournalsAsArrayChange);
  };
}

export default UsersRepository;
