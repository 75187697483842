import {
  createContext,
  useContext
} from 'react';

const AppProvidersContext = createContext(null);
const useAppProviders = () => useContext(AppProvidersContext);

export default AppProvidersContext;
export {
  useAppProviders
};
