import BaseDataRepository from '../BaseData';

const primaryKeyName = 'lrid';
const objectPluralName = 'LanguageResources';

class LanguageResourcesRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName);
  }
}

export default LanguageResourcesRepository;
