import BaseDataRepository from '../BaseData';

const primaryKeyName = 'fqid';
const objectPluralName = 'FAQList';
const objectSingularName = 'FAQ';
const objectPluralNameAsCamelCase = 'faqList';

class FAQListRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName, objectSingularName, objectPluralNameAsCamelCase);
  }
}

export default FAQListRepository;
