import 'firebase/database';
import {
  isBoolean,
  toFormattedDateTime,
  DATE_TIME_MOMENT_FORMAT,
  isNumber,
  isNullOrEmpty,
  refactorObject
} from '../../../../App';

const getDbItemsQuery = (existingDbItems, childName, childValue) => {
  let dbItemsQuery = existingDbItems.orderByChild(childName);
  if (!isNullOrEmpty(childValue)) {
    dbItemsQuery = dbItemsQuery.equalTo(childValue);
  }
  return dbItemsQuery;
};
const getFilteredDbItemsAsArray = async (dbItemsSnapshot, dbItemsAsArrayFilter) => {
  const dbItems = (await dbItemsSnapshot.val()) || {};
  const dbItemsAsArray = Object.keys(dbItems).map(key => dbItems[key]);
  const filteredDbItemsAsArray = dbItemsAsArray.filter(dbItemsAsArrayFilter);
  return filteredDbItemsAsArray;
};
class BaseDataRepository {
  constructor(firebaseApp, primaryKeyName, objectPluralName, objectSingularName = undefined, objectPluralNameAsCamelCase = undefined) {
    const {
      REACT_APP_USE_EMULATOR,
      REACT_APP_DTB_PORT
    } = process.env;
    this.db = firebaseApp.database(); // debugger;
    if (REACT_APP_USE_EMULATOR === 'true' && isNumber(REACT_APP_DTB_PORT)) {
      this.db.useEmulator('localhost', REACT_APP_DTB_PORT);
      console.log(`BaseDataRepository.db.useEmulator is set to: 'localhost:${REACT_APP_DTB_PORT}'`);
    }
    this.primaryKeyName = primaryKeyName;
    this.objectPluralName = objectPluralName;
    this.objectPluralNameAsCamelCase = objectPluralNameAsCamelCase !== undefined
      ? objectPluralNameAsCamelCase
      : `${objectPluralName.substring(0, 1).toLowerCase()}${objectPluralName.substring(1)}`;
    this.objectSingularName = objectSingularName !== undefined
      ? objectSingularName
      : objectPluralName.substring(0, objectPluralName.length - 1)
  }
  getDbRef = async primaryKey => {
    return await this.db.ref(`${this.objectPluralNameAsCamelCase}/${primaryKey || ''}`);
  }
  getDbItems = async () => {
    return await this.getDbRef();
  }
  getDbItemsAsArray = async (includeInactive = false, childName = 'active', childValue = undefined) => {
    const existingDbItems = await this.getDbItems();
    const dbItemsQuery = getDbItemsQuery(existingDbItems, childName, childValue);
    const dbItemsSnapshot = await dbItemsQuery.once('value');
    const filteredDbItemsAsArray = await getFilteredDbItemsAsArray(dbItemsSnapshot, dbItem => includeInactive || dbItem.active);
    return filteredDbItemsAsArray;
  }
  onDbItemsAsArrayChange = async (includeInactive = false, childName = 'active', childValue = undefined, handleDbItemsAsArrayChange = undefined) => {
    const existingDbItems = await this.getDbItems();
    const dbItemsQuery = getDbItemsQuery(existingDbItems, childName, childValue);
    const handleExistingDbItemValueChange = async dbItemsSnapshot => {
      if (typeof handleDbItemsAsArrayChange === 'function') {
        console.log(`${this.objectPluralNameAsCamelCase}.onDbItemsAsArrayChange:on called.`);
        const filteredDbItemsAsArray = await getFilteredDbItemsAsArray(dbItemsSnapshot, dbItem => includeInactive || dbItem.active);
        handleDbItemsAsArrayChange(filteredDbItemsAsArray);
      } else if (typeof dbItemsQuery === 'object') {
        console.log(`${this.objectPluralNameAsCamelCase}.onDbItemsAsArrayChange:off called.`);
        dbItemsQuery.off('value', handleExistingDbItemValueChange);
      }
    };
    return await dbItemsQuery.on('value', handleExistingDbItemValueChange);
  }
  getDbItem = async primaryKey => {
    return await this.getDbRef(primaryKey);
  }
  getDbItemValue = async primaryKey => { // debugger;
    const existingDbItem = await this.getDbItem(primaryKey);
    const dbItemSnapshot = await existingDbItem.once('value');
    const dbItem = await dbItemSnapshot.val();
    return dbItem;
  }
  onDbItemValueChange = async (primaryKey, handleDbItemValueChange) => { // debugger;
    const existingDbItem = await this.getDbItem(primaryKey);
    const handleExistingDbItemValueChange = async dbItemSnapshot => {
      if (dbItemSnapshot && typeof handleDbItemValueChange === 'function') {
        console.log(`${this.objectPluralNameAsCamelCase}.onDbItemValueChange:on called.`);
        const dbItem = await dbItemSnapshot.val();
        handleDbItemValueChange(dbItem);
        // } else if (typeof existingDbItem === 'object') {
        //   console.log(`${this.objectPluralNameAsCamelCase}.onDbItemValueChange:off called.`);
        //   existingDbItem.off('value', handleExistingDbItemValueChange);
      }
    };
    return await existingDbItem.on('value', handleExistingDbItemValueChange);
  }
  saveDbItem = async (item, saveDbItem_completed) => { // debugger;
    const {
      active,
      created,
      createdBy,
      [this.primaryKeyName]: primaryKey,
      updated,
      updatedBy,
      version,
      isNew,
      ...rest
    } = item;
    const refactoredRest = refactorObject(rest);
    const nowDateTime = toFormattedDateTime(new Date(), DATE_TIME_MOMENT_FORMAT);
    const preparedItem = {
      active: active || (isBoolean(active) ? false : undefined),
      [this.primaryKeyName]: primaryKey,
      updated: updated || nowDateTime,
      updatedBy: updatedBy || '',
      version: parseInt(version) || 1
    };
    let errorMessage = null;
    let existingDbItem = await this.getDbItem(primaryKey);
    let dbItemSnapshot = null;
    let dbItem = null; // debugger;
    if (isNew) {
      preparedItem.created = created || nowDateTime;
      preparedItem.createdBy = createdBy || '';
      if (primaryKey) {
        preparedItem[this.primaryKeyName] = primaryKey;
        existingDbItem.set({
          ...preparedItem,
          ...refactoredRest
        }, saveDbItem_completed);
      } else {
        dbItemSnapshot = await existingDbItem.push();
        preparedItem[this.primaryKeyName] = await dbItemSnapshot.getKey();
        dbItemSnapshot.set({
          ...preparedItem,
          ...refactoredRest
        }, saveDbItem_completed);
      }
    } else {
      dbItemSnapshot = await existingDbItem.once('value');
      dbItem = await dbItemSnapshot.val() || {};
      if (dbItem) {
        const {
          active: dbItemActive,
          created: dbItemCreated,
          createdBy: dbItemCreatedBy,
          [this.primaryKeyName]: dbItemPrimaryKey,
          updated: dbItemUpdated,
          updatedBy: dbItemUpdatedBy,
          version: dbItemVersion,
          ...dbItemRest
        } = dbItem;
        if (dbItemVersion === version) { // debugger;
          const refactoredDbItemRest = refactorObject(dbItemRest);
          preparedItem.version = (parseInt(version) || 0) + 1;
          existingDbItem.set({
            [this.primaryKeyName]: preparedItem[this.primaryKeyName] || dbItemPrimaryKey || undefined,
            active: preparedItem.active,
            created: dbItemCreated,
            createdBy: dbItemCreatedBy,
            updated: nowDateTime,
            updatedBy: preparedItem.updatedBy,
            ...refactoredDbItemRest,
            ...preparedItem,
            ...refactoredRest
          }, saveDbItem_completed);
        } else {
          errorMessage = `Your record has changed since being retrieved. Please refresh your screen.`;
        }
      } else {
        errorMessage = `primaryKey (${primaryKey}) not found.`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db ${this.objectSingularName} Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
    return preparedItem[this.primaryKeyName];
  }
  deleteDbItem = async primaryKey => {
    const existingDbItem = await this.getDbItem(primaryKey);
    let errorMessage = null;
    if (existingDbItem) {
      await existingDbItem.remove();
    } else {
      errorMessage = `primaryKey (${primaryKey}) not found.`;
    }
    if (errorMessage) {
      console.log(`Delete Db ${this.objectSingularName} Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
    return true;
  }
}

export default BaseDataRepository;
