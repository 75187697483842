import BaseDataRepository from '../BaseData';
import UserGroupsRepository from '../UserGroups';
import UsersRepository from '../Users';

const primaryKeyName = 'gid';
const objectPluralName = 'Groups';
const objectSingularName = 'Group';
const objectPluralNameAsCamelCase = 'groups';

class GroupsRepository extends BaseDataRepository {
  constructor(firebaseApp, usersRepository = undefined) {
    super(firebaseApp, primaryKeyName, objectPluralName, objectSingularName, objectPluralNameAsCamelCase);
    this.userGroups = new UserGroupsRepository(firebaseApp);
    this.users = (usersRepository || new UsersRepository(firebaseApp, this));
  }
  getUserGroups = async (gid, includeInactive = false, includeUsers = false) => {
    const userGroupsAsArray = await this.userGroups.getDbItemsAsArray(includeInactive);
    const userGroups = userGroupsAsArray
      .filter(ug =>
        ug.gid === gid); // debugger;
    if (includeUsers) {
      await Promise.all(userGroups.map(async userGroup => {
        const user = await this.users.getDbItemValue(userGroup.uid);
        if (!includeInactive && user.active) {
          userGroup.user = user;
        }
        return null;
      }));
    }
    return (userGroups || []).filter(ug => !includeInactive || ug.active);
  };
}

export default GroupsRepository;
