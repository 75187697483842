import BaseDataRepository from '../BaseData';

const primaryKeyName = 'ujid';
const objectPluralName = 'UserJournals';

class UserJournalsRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName);
  }
}

export default UserJournalsRepository;
