import {
  lazy
} from 'react';
import {
  // undefinedRole,
  whanauRole,
  studentRole,
  teacherRole,
  adminRole,
  systemAdminRole
} from '../Domain/Roles';

const publicLayout = lazy(async () => await import('../Layouts/PublicLayout'));
const authLayout = lazy(async () => await import('../Layouts/AuthLayout'));
const adminLayout = lazy(async () => await import('../Layouts/AdminLayout'));
const whanauRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole,
  studentRole,
  whanauRole
};
// const studentRoleUp = {
//   systemAdminRole,
//   adminRole,
//   teacherRole,
//   studentRole
// };
const teacherRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole
};
const adminRoleUp = {
  systemAdminRole,
  adminRole
};
const adminRoleOnly = {
  adminRole
};
const systemAdminRoleOnly = {
  systemAdminRole
};

export default {
  home: {
    isActive: true,
    layout: publicLayout,
    path: '/',
    component: lazy(async () => await import('../Views/HomeView')),
    isExact: true,
    isHash: false,
    clientId: 'home',
    displayName: 'Home',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  reactstrap: {
    isActive: true,
    layout: publicLayout,
    path: '/reactstrap',
    component: lazy(async () => await import('../Views/ReactstrapView')),
    isExact: true,
    isHash: false,
    clientId: 'reactstrap',
    displayName: 'reactstrap',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  privacyPolicy: {
    isActive: true,
    layout: publicLayout,
    path: '/privacyPolicy',
    component: lazy(async () => await import('../Views/PrivacyPolicyView')),
    isExact: true,
    isHash: false,
    clientId: 'privacyPolicy',
    displayName: 'Privacy Policy',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  termsOfUse: {
    isActive: true,
    layout: publicLayout,
    path: '/termsOfUse',
    component: lazy(async () => await import('../Views/TermsOfUseView')),
    isExact: true,
    isHash: false,
    clientId: 'termsOfUse',
    displayName: 'Terms of Use',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  signIn: {
    isActive: true,
    layout: publicLayout,
    path: '/signIn',
    component: lazy(async () => await import('../Views/SignInView')),
    isExact: true,
    isHash: false,
    clientId: 'signIn',
    displayName: 'Sign In',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  signUp: {
    isActive: true,
    layout: publicLayout,
    path: '/signUp',
    component: lazy(async () => await import('../Views/SignUpView')),
    isExact: true,
    isHash: false,
    clientId: 'signUp',
    displayName: 'Sign Up',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  forgotPassword: {
    isActive: true,
    layout: publicLayout,
    path: '/forgotPassword',
    component: lazy(async () => await import('../Views/ForgotPasswordView')),
    isExact: true,
    isHash: false,
    clientId: 'forgotPassword',
    displayName: 'Forgot Password',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  dashboard: {
    isActive: true,
    layout: authLayout,
    path: '/dashboard',
    component: lazy(async () => await import('../Views/DashboardView')),
    isExact: true,
    isHash: false,
    clientId: 'dashboard',
    displayName: 'Dashboard',
    showInDashboard: false,
    dashboardGroup: 'first',
    icon: 'fas fa-home',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  journals: {
    isActive: true,
    layout: authLayout,
    path: '/journals',
    params: {
      d: 'today',
      c: 'All'
    },
    component: lazy(async () => await import('../Views/JournalsView')),
    isExact: true,
    isHash: false,
    clientId: 'journals',
    displayName: 'Journals',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'far fa-newspaper',
    count: async state => (await state.backend.users.getUserJournals(state.authUser.uid, false, false, true)).length,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  notifications: {
    isActive: true,
    layout: authLayout,
    path: '/notifications',
    params: {
      d: 'today',
      c: 'All'
    },
    component: lazy(async () => await import('../Views/NotificationsView')),
    isExact: true,
    isHash: false,
    clientId: 'notifications',
    displayName: 'Notifications',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'far fa-bell',
    count: async state => (await state.backend.users.getUserNotifications(state.authUser.uid, false, false)).length,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  profile: {
    isActive: true,
    layout: authLayout,
    path: '/profile',
    component: lazy(async () => await import('../Views/ProfileView')),
    isExact: true,
    isHash: false,
    clientId: 'profile',
    displayName: 'Profile',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'fas fa-user',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  admin: {
    isActive: true,
    layout: adminLayout,
    path: '/admin',
    component: lazy(async () => await import('../Views/AdminView')),
    isExact: true,
    isHash: false,
    clientId: 'admin',
    displayName: 'Admin',
    showInDashboard: true,
    dashboardGroup: 'second',
    icon: 'fas fa-tools',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  users: {
    isActive: true,
    layout: adminLayout,
    path: '/users',
    component: lazy(async () => await import('../Views/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Users',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-users',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  user: {
    isActive: true,
    layout: adminLayout,
    path: '/users/:uid',
    component: lazy(async () => await import('../Views/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'User',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  teachers: {
    isActive: true,
    layout: adminLayout,
    path: '/teachers',
    component: lazy(async () => await import('../Views/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'teachers',
    displayName: 'Teachers',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard-teacher',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  teacher: {
    isActive: true,
    layout: adminLayout,
    path: '/teachers/:uid',
    component: lazy(async () => await import('../Views/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'teacher',
    displayName: 'Teacher',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard-teacher',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  students: {
    isActive: true,
    layout: adminLayout,
    path: '/students',
    component: lazy(async () => await import('../Views/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'students',
    displayName: 'Students',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-graduate',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  student: {
    isActive: true,
    layout: adminLayout,
    path: '/students/:uid',
    component: lazy(async () => await import('../Views/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'student',
    displayName: 'Student',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-graduate',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  whanauMembers: {
    isActive: true,
    layout: adminLayout,
    path: '/whanauMembers',
    component: lazy(async () => await import('../Views/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'whanauMembers',
    displayName: 'Whanau',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-friends',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  whanauMember: {
    isActive: true,
    layout: adminLayout,
    path: '/whanauMembers/:uid',
    component: lazy(async () => await import('../Views/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'whanauMember',
    displayName: 'Whanau Member',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-friends',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  reports: {
    isActive: true,
    layout: authLayout,
    path: '/reports',
    component: lazy(async () => await import('../Views/ReportsView')),
    isExact: true,
    isHash: false,
    clientId: 'reports',
    displayName: 'Reports',
    showInDashboard: true,
    dashboardGroup: 'second',
    icon: 'fas fa-file-alt',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  faq: {
    isActive: true,
    layout: authLayout,
    path: '/faq',
    component: lazy(async () => await import('../Views/FAQView')),
    isExact: true,
    isHash: false,
    clientId: 'faq',
    displayName: 'FAQ',
    showInDashboard: true,
    dashboardGroup: 'third',
    icon: 'fas fa-question-circle',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  signOut: {
    isActive: true,
    layout: authLayout,
    path: '/signOut',
    component: null,
    isExact: true,
    isHash: true,
    clientId: 'signOut',
    displayName: 'Sign Out',
    showInDashboard: true,
    dashboardGroup: 'third',
    icon: 'fas fa-sign-out-alt',
    count: null,
    onClick: async state => await state.backend.authentication.signOut(),
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  languages: {
    isActive: true,
    layout: adminLayout,
    path: '/languages',
    component: lazy(async () => await import('../Views/LanguagesView')),
    isExact: true,
    isHash: false,
    clientId: 'languages',
    displayName: 'Languages',
    showInDashboard: true,
    dashboardGroup: null,
    icon: 'fas fa-language',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  adminReports: {
    isActive: true,
    layout: adminLayout,
    path: '/adminReports',
    component: lazy(async () => await import('../Views/ReportsView')),
    isExact: true,
    isHash: false,
    clientId: 'adminReports',
    displayName: 'Reports',
    showInDashboard: true,
    dashboardGroup: null,
    icon: 'fas fa-file-alt',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  faqList: {
    isActive: true,
    layout: adminLayout,
    path: '/faqList',
    component: lazy(async () => await import('../Views/FAQListView')),
    isExact: true,
    isHash: false,
    clientId: 'faqList',
    displayName: 'FAQ List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-question-circle',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  faqListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/faqList/:fqid',
    component: lazy(async () => await import('../Views/FAQListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'faqListItem',
    displayName: 'FAQ List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-question-circle',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  notificationList: {
    isActive: true,
    layout: adminLayout,
    path: '/notificationList',
    component: lazy(async () => await import('../Views/NotificationListView')),
    isExact: true,
    isHash: false,
    clientId: 'notificationList',
    displayName: 'Notification List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-bell',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  notificationListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/notificationList/:nid',
    component: lazy(async () => await import('../Views/NotificationListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'notificationListItem',
    displayName: 'Notification List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-bell',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  classes: {
    isActive: true,
    layout: adminLayout,
    path: '/classes',
    component: lazy(async () => await import('../Views/GroupsView')),
    isExact: true,
    isHash: false,
    clientId: 'classes',
    displayName: 'Classes',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  classesItem: {
    isActive: true,
    layout: adminLayout,
    path: '/classes/:gid',
    component: lazy(async () => await import('../Views/GroupView')),
    isExact: true,
    isHash: false,
    clientId: 'class',
    displayName: 'Class',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  groups: {
    isActive: true,
    layout: adminLayout,
    path: '/groups',
    component: lazy(async () => await import('../Views/GroupsView')),
    isExact: true,
    isHash: false,
    clientId: 'groups',
    displayName: 'Groups',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-address-book',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  group: {
    isActive: true,
    layout: adminLayout,
    path: '/groups/:gid',
    component: lazy(async () => await import('../Views/GroupView')),
    isExact: true,
    isHash: false,
    clientId: 'group',
    displayName: 'Group',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-address-book',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  journalList: {
    isActive: true,
    layout: adminLayout,
    path: '/journalList',
    component: lazy(async () => await import('../Views/JournalListView')),
    isExact: true,
    isHash: false,
    clientId: 'journalList',
    displayName: 'Journal List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-newspaper',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  journalListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/journalList/:jid',
    component: lazy(async () => await import('../Views/JournalListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'journalListItem',
    displayName: 'Journal List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-newspaper',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  }
};
