import BaseDataRepository from '../BaseData';

const primaryKeyName = 'unid';
const objectPluralName = 'UserNotifications';

class UserNotificationsRepository extends BaseDataRepository {
  constructor(firebaseApp) {
    super(firebaseApp, primaryKeyName, objectPluralName);
  }
}

export default UserNotificationsRepository;
