import firebaseApp from 'firebase/app';
import {
  FunctionsRepository,
  AuthenticationRepository,
  StorageRepository,
  UsersRepository,
  LanguageResourcesRepository,
  FAQListRepository,
  NotificationListRepository,
  UserNotificationsRepository,
  UserConnectionsRepository,
  GroupsRepository,
  UserGroupsRepository,
  JournalListRepository,
  UserJournalsRepository
} from '../Firebase/Repositories';

const {
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
  REACT_APP_FIREBASE_DATABASE_URL: databaseURL,
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_APP_ID: appId,
  REACT_APP_FIREBASE_MEASUREMENT_ID: measurementId
} = process.env;
const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId
};

class Firebase {
  constructor() {
    this.app = firebaseApp.apps.length
      ? firebaseApp.app()
      : firebaseApp.initializeApp(config);
    this.functions = new FunctionsRepository(firebaseApp);
    this.authentication = new AuthenticationRepository(firebaseApp);
    this.storage = new StorageRepository(firebaseApp);
    this.users = new UsersRepository(firebaseApp);
    this.languageResources = new LanguageResourcesRepository(firebaseApp);
    this.faq = new FAQListRepository(firebaseApp);
    this.notifications = new NotificationListRepository(firebaseApp);
    this.userNotifications = new UserNotificationsRepository(firebaseApp);
    this.userConnections = new UserConnectionsRepository(firebaseApp);
    this.groups = new GroupsRepository(firebaseApp);
    this.userGroups = new UserGroupsRepository(firebaseApp);
    this.journals = new JournalListRepository(firebaseApp);
    this.userJournals = new UserJournalsRepository(firebaseApp);
  }
}

export default Firebase;
