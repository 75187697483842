import React, {
  Suspense
} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Switch
} from 'react-router-dom';
import AppRoute from './components/Layouts/AppRoute';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import routes from './components/Routes';
import LoadingSpinner from './components/LoadingSpinner';
import {
  DEFAULT_LANGUAGE
} from './components/Domain';
import {
  initReactI18next
} from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import AppProviders from './components/Contexts/AppProviders/Provider';
import Firebase from './components/Domain/Firebase';

const activeRouteKeys = Object.keys(routes).filter(key => routes[key].isActive && !routes[key].isHash);
const initPumaharaI18next = {
  interpolation: {
    escapeValue: false
  },
  lng: DEFAULT_LANGUAGE,
  resources: {
    en: {
      common: {} // enCommonJson
    },
    mi: {
      common: {} // miCommonJson
    }
  },
  backend: {
    backends: [
      LocalStorageBackend,
      HttpApi
    ],
    backendOptions: [{
      store: localStorage
    }, {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }]
  }
};
const {
  REACT_APP_DEBUG_MODE
} = process.env;

if (REACT_APP_DEBUG_MODE === 'false') {
  window['console']['log'] = () => { };
}
// console.log(`App: ${JSON.stringify({
//   initPumaharaI18next
// }, null, 2)}`);
i18next
  .use(Backend)
  .use(initReactI18next)
  .init(initPumaharaI18next);
ReactDOM.render(
  <Suspense fallback={<LoadingSpinner
    color="light"
    outerClassName="pumahara-loading"
  />}>
    <BrowserRouter>
      <AppProviders i18next={i18next} backend={new Firebase()}>
        <Switch>
          {
            activeRouteKeys.map(activeRouteKey => {
              const activeRoute = routes[activeRouteKey];
              const {
                layout,
                path,
                component,
                isExact
              } = activeRoute;
              return (
                <AppRoute
                  layout={layout}
                  path={path}
                  component={component}
                  exact={isExact}
                  key={activeRouteKey}
                />
              );
            })
          }
        </Switch>
      </AppProviders>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);
