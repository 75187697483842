import React, {
  useState,
  useEffect,
  lazy
} from 'react';
// import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const LoadingSpinner = lazy(async () => await import('../LoadingSpinner'));
const INITIAL_STATE = {
  isLoading: true,
  keyField: '',
  columns: [],
  data: []
};
const AdminImportTable = props => {
  const [state, setState] = useState(INITIAL_STATE);
  const {
    isLoading,
    keyField,
    columns,
    data
  } = state;
  useEffect(() => {
    if (isLoading) {
      const {
        onLoad
      } = props; // debugger;
      const {
        keyField,
        columns,
        data
      } = onLoad();
      setState(s => ({
        ...s,
        isLoading: false,
        keyField,
        columns,
        data
      }));
    }
    return () => { }
  }, [props, isLoading]);
  return (
    <>
      {
        isLoading
          ? <>
            <LoadingSpinner
              color="dark"
              outerClassName="pumahara-loading"
            />
          </>
          : <>
            <BootstrapTable
              bootstrap4
              keyField={keyField}
              columns={columns}
              data={data}
            />
          </>
      }
    </>
  );
};

AdminImportTable.propTypes = {

}

export default AdminImportTable;
