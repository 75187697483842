import BaseDataRepository from '../BaseData';
import UserJournalsRepository from '../UserJournals';
import UsersRepository from '../Users';

const primaryKeyName = 'jid';
const objectPluralName = 'JournalList';
const objectSingularName = 'Journal';
const objectPluralNameAsCamelCase = 'journalList';

class JournalListRepository extends BaseDataRepository {
  constructor(firebaseApp, usersRepository = undefined) {
    super(firebaseApp, primaryKeyName, objectPluralName, objectSingularName, objectPluralNameAsCamelCase);
    this.userJournals = new UserJournalsRepository(firebaseApp);
    this.users = (usersRepository || new UsersRepository(firebaseApp, undefined, this));
  }
  getUserJournals = async (jid, includeInactive = false, includeRead = true, includeUsers = false) => {
    const userJournalsAsArray = await this.userJournals.getDbItemsAsArray(includeInactive, 'jid', jid);
    const userJournals = userJournalsAsArray
      .filter(uj =>
        (includeRead || !uj.isRead)); // debugger;
    if (includeUsers) {
      await Promise.all(userJournals.map(async userJournal => {
        const user = await this.users.getDbItemValue(userJournal.uid);
        if (!includeInactive && user.active) {
          userJournal.user = user;
        }
        return null;
      }));
    }
    return (userJournals || [])
      .filter(uj =>
        (!includeInactive || uj.active) &&
        (!includeUsers || uj.user));
  };
}

export default JournalListRepository;
